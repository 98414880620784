{
  //Block right click
  document.addEventListener(
    "contextmenu",
    (e) => {
      e.preventDefault();
    },
    false
  );

  //Disable View Source
  document.addEventListener("keydown", (e) => {
    // USE THIS TO DISABLE CONTROL AND ALL FUNCTION KEYS
    // if (e.ctrlKey || (e.keyCode>=112 && e.keyCode<=123)) {
    // THIS WILL ONLY DISABLE CONTROL AND F12
    if (e.ctrlKey || e.keyCode == 123) {
      e.stopPropagation();
      e.preventDefault();
    }
  });

  //Countdown;

  var countDownDate = new Date("2024-10-11 22:00:00").getTime();

  var intervalcountDownDate = setInterval(function () {
    var now = new Date().getTime();
    var distance = countDownDate - now;

    var days = 0;
    var hours = 0;
    var minutes = 0;
    var seconds = 0;

    if (distance > 0) {
      days = Math.floor(distance / (1000 * 60 * 60 * 24));
      hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      seconds = Math.floor((distance % (1000 * 60)) / 1000);
    } else {
      clearInterval(intervalcountDownDate);
    }

    var daysSpan = document.getElementsByClassName("countdown-timer__days");
    var hoursSpan = document.getElementsByClassName("countdown-timer__hours");
    var minutesSpan = document.getElementsByClassName(
      "countdown-timer__minutes"
    );
    var secondsSpan = document.getElementsByClassName(
      "countdown-timer__seconds"
    );

    for (var daySpan of daysSpan)
      daySpan.innerHTML =
        "<div>" +
        days +
        "</div><small class='countdown-timer__label'>Days</small>";

    for (var hourSpan of hoursSpan)
      hourSpan.innerHTML =
        "<div>" +
        hours +
        "</div><small class='countdown-timer__label'>Hours</small>";

    for (var minuteSpan of minutesSpan)
      minuteSpan.innerHTML =
        "<div>" +
        minutes +
        "</div><small class='countdown-timer__label'>Minutes</small>";

    for (var secondSpan of secondsSpan)
      secondSpan.innerHTML =
        "<div>" +
        seconds +
        "</div><small class='countdown-timer__label'>Seconds</small>";
  }, 1000);

  // Counters
  function isInViewport(el) {
    const rect = el.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      Math.round(rect.bottom) <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      Math.round(rect.right) <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  const box = document.querySelector(".counter-block");

  var checkCounterIsInViewPort = function () {
    if (!isInViewport(box)) return;

    for (var counter of counters) {
      counter.innerText = "0";
      updateCounter(counter);
    }

    document.removeEventListener("scroll", checkCounterIsInViewPort);
  };

  document.addEventListener("scroll", checkCounterIsInViewPort, {
    passive: true,
  });

  var counters = document.getElementsByClassName("counter");

  function updateCounter(counterParam) {
    var targetValue = +counterParam.getAttribute("data-target");
    var actualValue = +counterParam.innerText;

    var increment = targetValue / 300;

    if (actualValue < targetValue) {
      counterParam.innerText = `${Math.ceil(actualValue + increment)}`;
      setTimeout(updateCounter, 1, counterParam);
    } else {
      counterParam.innerText = targetValue;
    }
  }

  // Collapisble

  var collapsibles = document.querySelectorAll(".collapsible");
  collapsibles.forEach((item) =>
    item.addEventListener("click", function () {
      this.classList.toggle("collapsible--expanded");
    })
  );

  var joinCartBtns = document.getElementsByClassName("join-cart");

  for (var joinCartBtn of joinCartBtns) {
    joinCartBtn.addEventListener("click", () => {
      window.location.href =
        "https://sklep.modestprogrammer.pl/koszyk-dodaj?productId=73&quantity=1";
      //addToCart(52, 1, 3);
    });
  }

  // Carousel
  // opinion__dot--fill
  var opinions = document.getElementsByClassName("carousel__all")[0];
  var leftArrow = document.getElementsByClassName("carousel__arrow-left")[0];
  var rightArrow = document.getElementsByClassName("carousel__arrow-right")[0];

  var opinion = document.getElementsByClassName("carousel__content");

  var dots = document.getElementsByClassName("carousel__dot");

  var indexCarousel = 0;

  var interval = setInterval(startCarousel, 4000);

  function startCarousel() {
    indexCarousel++;
    changeOpinion();
  }

  function changeOpinion() {
    if (indexCarousel > opinion.length - 1) {
      indexCarousel = 0;
    } else if (indexCarousel < 0) {
      indexCarousel = opinion.length - 1;
    }

    opinions.style.transform = `translateX(${-indexCarousel * 60}vw)`;

    var filledDots = document.getElementsByClassName("carousel__dot--fill");

    for (var elements of filledDots) {
      elements.classList.remove("carousel__dot--fill");
    }

    dots[indexCarousel].classList.add("carousel__dot--fill");
  }

  function resetInterval() {
    clearInterval(interval);
    interval = setInterval(startCarousel, 4000);
  }

  rightArrow.addEventListener("click", () => {
    indexCarousel++;
    changeOpinion();
    resetInterval();
  });

  leftArrow.addEventListener("click", () => {
    indexCarousel--;
    changeOpinion();
    resetInterval();
  });

  // Modal
  var modal = document.getElementById("myModal");

  var btns = document.getElementsByClassName("modal-open");

  var modalSuccess = document.getElementsByClassName("modal__success")[0];

  modalSuccess.style.display = "none";

  for (var btn of btns) {
    btn.onclick = function () {
      modal.style.display = "block";
    };
  }

  var spanClose = document.getElementsByClassName("close")[0];

  spanClose.onclick = function () {
    modal.style.display = "none";
    modalSuccess.style.display = "none";
  };

  window.onclick = function (event) {
    if (event.target == modal) {
      modal.style.display = "none";
      modalSuccess.style.display = "none";
    }
  };

  window.submitForm = function submitForm() {
    var form = document.getElementById("myForm");

    if (!form.checkValidity()) {
      if (form.reportValidity) {
        form.reportValidity();
      }
    } else {
      callAjax();
    }
  };

  function callAjax() {
    document.getElementById("loader").style.display = "flex";
    var emailnputValue = document.getElementById("email").value.trim();

    $.ajax({
      url: "https://www.modestprogrammer.pl/api/myapi2",
      type: "POST",
      data: {
        email: emailnputValue,
        api_key: "9814",
      },
      success: function (returnhtml) {
        document.getElementById("email").value = "";
        modalSuccess.style.display = "block";
        document.getElementById("loader").style.display = "none";
      },
    });
  }
}
